import { useApolloClient } from "@apollo/client";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSignInContainer from "./SignInContainer.hook";
import facebookAuthIcon from "../../../../assets/icon/facebook_auth.png";
import googleAuthIcon from "../../../../assets/icon/google_auth.png";
import kakaoAuthIcon from "../../../../assets/icon/kakao_auth.png";
import { GetMyUserProfileDocument } from "../../../../lib/apollo/graphql/generated";
import {
  firebaseSignInWithFacebook,
  firebaseSignInWithGoogle,
} from "../../../../lib/firebase";
import { SCREENS } from "../../../../lib/route/utils/router";
import theme from "../../../../lib/theme";
import Button from "../../../common/components/Button";
import Flex from "../../../common/components/Flex";
import Input from "../../../common/components/Input";
import Layout from "../../../common/components/Layout";
import Section from "../../../common/components/Section";
import Text from "../../../common/components/Text";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import { signInWithKakao, useKakaoAuth } from "../../common/utils/kakaoAuth";

function SignInContainer() {
  const client = useApolloClient();
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const navigate = useNavigate();

  useKakaoAuth();

  const {
    models: { state, loggingIn },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      isSubmitDisabled,
      onSubmit,
    },
  } = useSignInContainer();

  const socialAuthButtons = useMemo(
    () => [
      {
        icon: googleAuthIcon,
        onClick: async () => {
          try {
            await firebaseSignInWithGoogle();
            const { data } = await client.query({
              query: GetMyUserProfileDocument,
            });

            if (!data?.getMyUserProfile) {
              navigate(SCREENS.SIGN_UP + "/step2");
            }
          } catch (err) {
            console.log(err);
          }
        },
      },
      {
        icon: kakaoAuthIcon,
        onClick: async () => {
          signInWithKakao();
        },
      },
      {
        icon: facebookAuthIcon,
        onClick: async () => {
          try {
            await firebaseSignInWithFacebook();
            const { data } = await client.query({
              query: GetMyUserProfileDocument,
            });

            if (!data?.getMyUserProfile) {
              navigate(SCREENS.SIGN_UP + "/step2");
            }
          } catch (err) {
            console.log(err);
          }
        },
      },
    ],
    []
  );

  const { email, password, emailError, passwordError } = state;

  const memoizedEmailInput = useMemo(
    () => (
      <Input
        label={t("common.email")}
        placeholder={t("common.emailPlaceholder")}
        type="email"
        value={email}
        errorMessage={emailError}
        onFocus={() => onFocus("emailError")}
        onBlur={() => onBlur("emailError")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "email")
        }
      />
    ),
    [email, emailError]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <Input
        label={t("common.password")}
        placeholder={t("common.passwordPlaceholder")}
        type="password"
        value={password}
        errorMessage={passwordError}
        onFocus={() => onFocus("passwordError")}
        onBlur={() => onBlur("passwordError")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "password")
        }
        onKeyDown={onKeyDown}
      />
    ),
    [password, passwordError]
  );

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <Flex
          paddingTop={theme.spacing[isMobile ? 16 : 48]}
          flexDirection="column"
          alignItems="center"
          width="100%"
          marginBottom={theme.spacing[isMobile ? 16 : 48]}
        >
          <Text
            typography={isMobile ? "heading3" : "heading2"}
            color={theme.color.neutral900}
            marginBottom={theme.spacing[isMobile ? 24 : 32]}
          >
            {t("signIn.title")}
          </Text>
          <Text
            typography="body1"
            color={theme.color.neutral900}
            marginBottom={theme.spacing[isMobile ? 16 : 48]}
          >
            {t("signIn.notRegisteredYet")}{" "}
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => navigate(SCREENS.SIGN_UP + "/intro")}
            >
              {t("signIn.signUp")}
            </strong>
          </Text>
          <Flex
            flexDirection="column"
            width="100%"
            gap={theme.spacing[isMobile ? 16 : 24]}
            marginBottom={theme.spacing[isMobile ? 16 : 48]}
          >
            {memoizedEmailInput}
            {memoizedPasswordInput}
          </Flex>
          <Button
            fullWidth
            text={loggingIn ? t("signIn.signingIn") : t("signIn.signIn")}
            round={false}
            size={isMobile ? "sm" : "lg"}
            disabled={isSubmitDisabled()}
            onClick={onSubmit}
          />
        </Flex>
        <Flex
          paddingTop={theme.spacing[isMobile ? 16 : 48]}
          flexDirection="column"
          alignItems="center"
          width="100%"
          marginBottom={theme.spacing[isMobile ? 16 : 48]}
          gap={theme.spacing[24]}
        >
          <Text
            typography={isMobile ? "heading5" : "heading3"}
            color={theme.color.neutral700}
          >
            {t("signIn.socialAuthTitle")}
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"
            gap={theme.spacing[24]}
          >
            {socialAuthButtons.map((socialAuth) => (
              <div
                key={socialAuth.icon}
                style={{ cursor: "pointer" }}
                onClick={socialAuth.onClick}
              >
                <img src={socialAuth.icon} style={{ width: 60, height: 60 }} />
              </div>
            ))}
          </Flex>
        </Flex>
      </Section>
    </Layout>
  );
}

export default SignInContainer;
