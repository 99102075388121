import { ReactChildrenProp } from "global";
import React, { useState } from "react";
import Flex from "./Flex";
import IconButton from "./IconButton";
import Text from "./Text";
import minusIcon from "../../../assets/icon/minus.png";
import plusIcon from "../../../assets/icon/plus.png";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface AccordionProps extends ReactChildrenProp {
  title?: string;
  defaultState?: boolean;
}

function Accordion({ children, title, defaultState = false }: AccordionProps) {
  const [show, setShow] = useState(defaultState);

  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[isMobile ? 16 : 24]}
    >
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        padding={`${theme.spacing[isMobile ? 16 : 20]}px ${
          theme.spacing[16]
        }px`}
        boxShadow="0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset"
        onClick={() => setShow(!show)}
      >
        <Text
          flex={1}
          typography={isMobile ? "heading4" : "heading3"}
          color={theme.color.neutral900}
        >
          {title}
        </Text>
        <IconButton
          icon={show ? minusIcon : plusIcon}
          variant="text"
          size="sm"
        />
      </Flex>
      {show && children}
    </Flex>
  );
}

export default Accordion;
