import React from "react";
import { useParams } from "react-router-dom";
import { UserType } from "../../../../../lib/apollo/graphql/generated";
import theme from "../../../../../lib/theme";
import Layout from "../../../../common/components/Layout";
import PersonalProfileForm from "../../../../common/components/PersoanlProfileForm";
import Section from "../../../../common/components/Section";
import { useScreenWidthContext } from "../../../../common/provider/ScreenWidthProvider";
import SignUpStepper from "../../../common/components/SignUpStepper";

function SignUpStep2Container() {
  const params = useParams();

  const userType = params?.userType;

  const { isMobile } = useScreenWidthContext();

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <SignUpStepper />

        <PersonalProfileForm isCreating />
      </Section>
    </Layout>
  );
}

export default SignUpStep2Container;
