import ZoomVideo, { VideoClient } from "@zoom/videosdk";
import { ReactChildrenProp } from "global";
import React, { createContext, useContext } from "react";

const zoomClient = ZoomVideo.createClient();

const ZoomContext = createContext<typeof VideoClient>(zoomClient);

function ZoomProvider({ children }: ReactChildrenProp) {
  return (
    <ZoomContext.Provider value={zoomClient}>{children}</ZoomContext.Provider>
  );
}

export default ZoomProvider;

export function useZoomContext() {
  return useContext(ZoomContext);
}
