import React from "react";
import Button from "../../../common/components/Button";

interface SocialAuthButtonProps {
  icon?: string;
  text: string;
  onClick?: () => void;
}

function SocialAuthButton({ icon, text, onClick }: SocialAuthButtonProps) {
  return (
    <Button
      leftIcon={icon}
      text={text}
      fullWidth
      variant="outline"
      bgColor="neutralWhite"
      fontColor="neutral900"
      onClick={onClick}
    />
  );
}

export default SocialAuthButton;
