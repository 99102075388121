import React from "react";
import { Spacing } from "theme";
import theme from "../../../lib/theme";

interface DividerProps {
  horizontal?: boolean;
  size?: keyof Spacing | number;
  full?: boolean;
}

function Divider({ horizontal = true, size = 8, full = false }: DividerProps) {
  return (
    <div
      style={{
        width: horizontal ? (full ? "100%" : "50%") : size,
        height: horizontal ? size : full ? "100%" : "50%",
        backgroundColor: theme.color.neutral800,
      }}
    />
  );
}

export default Divider;
