import { Theme } from "theme";

const theme: Theme = {
  appMaxWidth: 1130,
  color: {
    primary1: "#220175",
    primary2: "#5A38FD",
    primary3: "#82B1FF",
    primary4: "#A3C2F2",
    secondary1: "#48DCC8",
    secondary2: "#94EDE1",
    secondary3: "#D8F5F1",
    tertiary1: "#CFFF1E",
    tertiary2: "#ECFBB5",
    tertiary3: "#F7FEE1",
    fourth1: "#14471E",
    fourth2: "#D0D7B8",
    fourth3: "#EBF1D9",
    fourth4: "#F8FAF1",
    neutral900: "#1F2223",
    neutral800: "#363939",
    neutral700: "#57595A",
    neutral600: "#79797B",
    neutral500: "#8E9090",
    neutral400: "#B1B2B2",
    neutral300: "#D2D2D3",
    neutral200: "#F6F6F6",
    neutralWhite: "#FFFFFF",
    error: "#ff5033",
  },
  typography: {
    heading1: {
      fontSize: 40,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    heading2: {
      fontSize: 32,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    heading3: {
      fontSize: 24,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    heading4: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    heading5: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    body1: {
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: 1.5,
    },
    body1Bold: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: 1.5,
    },
    body2Bold: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
    },
    body3: {
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: 1.5,
    },
    body3Bold: {
      fontSize: 12,
      fontWeight: 800,
      lineHeight: 1.5,
    },
    body4: {
      fontSize: 10,
      fontWeight: "normal",
      lineHeight: 1.5,
    },
    body4Bold: {
      fontSize: 10,
      fontWeight: 800,
      lineHeight: 1.5,
    },
    label1: {
      fontSize: 18,
      fontWeight: "normal",
      lineHeight: 1.25,
    },
    label1Bold: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    label2: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: 1.25,
    },
    label2Bold: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.25,
    },
    label3: {
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: 1.25,
    },
    label3Bold: {
      fontSize: 12,
      fontWeight: 800,
      lineHeight: 1.25,
    },
  },
  spacing: {
    2: 2,
    4: 4,
    8: 8,
    10: 10,
    12: 12,
    16: 16,
    20: 20,
    24: 24,
    32: 32,
    40: 40,
    48: 48,
    64: 64,
  },
};

export default theme;
