import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../../lib/route/utils/router";
import { useKakaoAuth } from "../../../common/utils/kakaoAuth";

function useSignUpIntroContainer() {
  const navigate = useNavigate();

  useKakaoAuth();

  const [agreed, setAgreed] = useState(true);

  function onAgreeClick() {
    setAgreed(!agreed);
  }

  return {
    models: {
      agreed,
    },
    operations: {
      onAgreeClick,
    },
  };
}

export default useSignUpIntroContainer;
