import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../lib/route/utils/router";
import { useAuthContext } from "../../../common/provider/AuthProvider";

const CLIENT_ID = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;

export function signInWithKakao() {
  const REDIRECT_URI = window.location.origin + SCREENS.KAKAO_CALLBACK;

  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  window.location.href = kakaoURL;
}

export function useKakaoAuth() {
  const { firebaseUser, profile } = useAuthContext();

  const navigate = useNavigate();

  function onAfterSubmit() {
    navigate(SCREENS.HOME);
  }

  useEffect(() => {
    if (!!firebaseUser && !!profile) {
      onAfterSubmit();
    }

    if (!!firebaseUser && !profile) {
      navigate(SCREENS.SIGN_UP + "/step2");
    }
  }, [firebaseUser, profile]);
}
