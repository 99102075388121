import { Stream } from "@zoom/videosdk";
import { ReactChildrenProp } from "global";
import React, { createContext } from "react";

export type MediaStream = typeof Stream;

interface MediaContext {
  audio: {
    encode: boolean;
    decode: boolean;
  };
  video: {
    encode: boolean;
    decode: boolean;
  };
  share: {
    encode: boolean;
    decode: boolean;
  };
  mediaStream: MediaStream | null;
}

export const ZoomMediaContext = createContext<MediaContext | null>(null);
