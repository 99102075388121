import React from "react";
import { Color } from "theme";
import Flex from "./Flex";
import theme from "../../../lib/theme";

export interface ButtonProps {
  fullWidth?: boolean;
  variant?: "contained" | "outline" | "text";
  text?: string;
  size?: "lg" | "md" | "sm";
  round?: boolean;
  bgColor?: keyof Color | "transparent";
  leftIcon?: string;
  rightIcon?: string;
  disabled?: boolean;
  borderColor?: keyof Color;
  fontColor?: keyof Color;
  onClick?: () => void;
}

function Button({
  fullWidth = false,
  variant = "contained",
  text,
  size = "md",
  round = true,
  bgColor = "primary1",
  leftIcon,
  rightIcon,
  disabled = false,
  borderColor = "neutral700",
  fontColor = "neutral200",
  onClick,
}: ButtonProps) {
  const padding =
    size === "sm"
      ? `${theme.spacing[8]}px ${theme.spacing[20]}px`
      : `${theme.spacing[8]}px ${theme.spacing[24]}px`;

  const iconSize = size === "sm" ? 16 : size === "md" ? 20 : 24;

  const gap = size === "sm" ? theme.spacing[8] : theme.spacing[10];

  const font =
    size === "sm"
      ? theme.typography.label3Bold
      : size === "md"
      ? theme.typography.label2Bold
      : theme.typography.heading5;

  return (
    <Flex
      cursor={disabled ? "auto" : "pointer"}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      backgroundColor={
        bgColor === "transparent"
          ? bgColor
          : variant !== "contained"
          ? !!bgColor
            ? theme.color[bgColor]
            : theme.color.neutralWhite
          : disabled
          ? theme.color.neutral600
          : theme.color[bgColor]
      }
      width={fullWidth ? "100%" : "fit-content"}
      borderRadius={round ? 5 : 0}
      padding={padding}
      gap={gap}
      outline={
        variant === "outline" ? `1px solid ${theme.color[borderColor]}` : "none"
      }
      onClick={() => {
        if (disabled) return;

        onClick && onClick();
      }}
    >
      {!!leftIcon && (
        <img src={leftIcon} style={{ width: iconSize, height: iconSize }} />
      )}
      {!!text && (
        <div
          style={{
            ...font,
            color: disabled ? theme.color.neutralWhite : theme.color[fontColor],
          }}
        >
          {text}
        </div>
      )}
      {!!rightIcon && (
        <img src={rightIcon} style={{ width: iconSize, height: iconSize }} />
      )}
    </Flex>
  );
}

export default Button;
