import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import { useGetLanguageOptionsQuery } from "../../../lib/apollo/graphql/generated";
import theme from "../../../lib/theme";

interface LanguageOptionInputProps {
  label?: string | number;
  selectedOptionIds: string[] | null;
  onOptionSelect: (optionId: string) => void;
}

function LanguageOptionInput({
  label,
  selectedOptionIds,
  onOptionSelect,
}: LanguageOptionInputProps) {
  const { i18n } = useTranslation();

  const { loading, data } = useGetLanguageOptionsQuery({
    variables: {
      language: i18n.resolvedLanguage,
    },
  });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={label} />}
      <Flex
        width="100%"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing[8]}
        flexWrap="wrap"
      >
        {!!data?.getLanguageOptions &&
          data?.getLanguageOptions?.map((option) => {
            const isSelected = selectedOptionIds?.includes(option?.id!);
            return (
              <Button
                key={option?.id}
                size="sm"
                round={false}
                text={option?.name}
                variant={isSelected ? "contained" : "outline"}
                fontColor={isSelected ? "neutralWhite" : "neutral700"}
                borderColor={isSelected ? "primary1" : "neutral700"}
                bgColor={isSelected ? "primary1" : "neutralWhite"}
                onClick={() => onOptionSelect(option?.id!)}
              />
            );
          })}
      </Flex>
    </Flex>
  );
}

export default LanguageOptionInput;
