import React from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";

export interface AvatarProps {
  uri?: string | null;
  size?: number;
  initial?: string;
  online?: boolean;
  showOnline?: boolean;
  onClick?: () => void;
}

function Avatar({
  uri,
  size = 120,
  initial,
  online = false,
  showOnline = true,
  onClick = undefined,
}: AvatarProps) {
  return (
    <Flex
      position="relative"
      className="avatar"
      borderRadius={size / 2}
      width={size}
      height={size}
      backgroundColor={theme.color.neutral300}
      alignItems="center"
      justifyContent="center"
      onClick={onClick && onClick}
    >
      {uri ? (
        <img
          src={uri}
          style={{
            borderRadius: size / 2,
            width: size,
            height: size,
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{
            fontSize: (size * 120) / 280,
            color: theme.color.neutralWhite,
            textAlign: "center",
          }}
        >
          {initial || ""}
        </div>
      )}

      {showOnline && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: size / 3,
            height: size / 3,
            borderRadius: size / 6,
            backgroundColor: online ? "#CFFF1E" : theme.color.secondary1,
          }}
        />
      )}
    </Flex>
  );
}

export default Avatar;
