import React from "react";
import { Spacing } from "theme";

interface SpacerProps {
  horizontal?: boolean;
  size?: keyof Spacing;
}

function Spacer({ horizontal = false, size = 8 }: SpacerProps) {
  return (
    <div
      style={{ width: horizontal ? size : 0, height: horizontal ? 0 : size }}
    />
  );
}

export default Spacer;
