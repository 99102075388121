import { ReactChildrenProp } from "global";
import React from "react";
import { ScrollRestoration } from "react-router-dom";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

interface LayoutProps extends ReactChildrenProp {
  authRequired?: boolean;
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <ScrollRestoration />
      <NavigationBar />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
