import React from "react";
import theme from "../../../../../lib/theme";
import AdditionalProfileForm from "../../../../common/components/AdditionalProfileForm";
import Layout from "../../../../common/components/Layout";
import Section from "../../../../common/components/Section";
import { useScreenWidthContext } from "../../../../common/provider/ScreenWidthProvider";
import SignUpStepper from "../../../common/components/SignUpStepper";

function SignUpStep3Container() {
  const { isMobile } = useScreenWidthContext();

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <SignUpStepper />

        <AdditionalProfileForm isCreating />
      </Section>
    </Layout>
  );
}

export default SignUpStep3Container;
