import { useApolloClient } from "@apollo/client";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSignUpIntroContainer from "./SignUpIntroContainer.hook";
import emailAuthIcon from "../../../../../assets/icon/email_auth.png";
import facebookAuthIcon from "../../../../../assets/icon/facebook_auth.png";
import googleAuthIcon from "../../../../../assets/icon/google_auth.png";
import kakaoAuthIcon from "../../../../../assets/icon/kakao_auth.png";
import signUpImage from "../../../../../assets/image/signup_image.png";
import {
  firebaseSignInWithFacebook,
  firebaseSignInWithGoogle,
} from "../../../../../lib/firebase";
import { SCREENS } from "../../../../../lib/route/utils/router";
import theme from "../../../../../lib/theme";
import Button from "../../../../common/components/Button";
import Flex from "../../../../common/components/Flex";
import Layout from "../../../../common/components/Layout";
import Section from "../../../../common/components/Section";
import Text from "../../../../common/components/Text";
import { useScreenWidthContext } from "../../../../common/provider/ScreenWidthProvider";
import SocialAuthButton from "../../../common/components/SocialAuthButton";
import {
  GetMyUserProfileDocument,
  UserType,
} from "../../../../../lib/apollo/graphql/generated";
import { signInWithKakao } from "../../../common/utils/kakaoAuth";

function SignUpIntroContainer() {
  const client = useApolloClient();
  const { isMobile } = useScreenWidthContext();
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const {
    models: { agreed },
    operations: { onAgreeClick },
  } = useSignUpIntroContainer();

  const authOptions = useMemo(
    () => [
      {
        icon: kakaoAuthIcon,
        text: t("common.kakaoAuth"),
        onClick: async () => {
          signInWithKakao();
        },
      },
      {
        icon: googleAuthIcon,
        text: t("common.googleAuth"),
        onClick: async () => {
          try {
            await firebaseSignInWithGoogle();
            const { data } = await client.query({
              query: GetMyUserProfileDocument,
            });

            if (!data?.getMyUserProfile) {
              navigate(SCREENS.SIGN_UP + "/step2");
            }
          } catch (err) {
            console.log(err);
          }
        },
      },
      {
        icon: facebookAuthIcon,
        text: t("common.facebookAuth"),
        onClick: async () => {
          try {
            await firebaseSignInWithFacebook();
            const { data } = await client.query({
              query: GetMyUserProfileDocument,
            });

            if (!data?.getMyUserProfile) {
              navigate(SCREENS.SIGN_UP + "/step2");
            }
          } catch (err) {
            console.log(err);
          }
        },
      },
      {
        icon: emailAuthIcon,
        text: t("common.emailAuth"),
        onClick: () => {
          navigate(SCREENS.SIGN_UP + "/step1");
        },
      },
    ],

    []
  );

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <Flex flexDirection="column" alignItems="center" width="100%">
          <Text
            typography={isMobile ? "heading3" : "heading2"}
            color={theme.color.neutral900}
            marginBottom={theme.spacing[24]}
          >
            {t("signUp.signUpTitle")}
          </Text>
          <img
            src={signUpImage}
            style={{
              width: isMobile ? 320 : 409,
              height: isMobile ? 280 : 293,
              marginBottom: theme.spacing[24],
            }}
          />
          <Text
            typography={"heading4"}
            color={theme.color.neutral900}
            marginBottom={theme.spacing[16]}
          >
            {`${t("signUp.signUpIntroTitle1")}${isMobile ? "\n" : " "}${t(
              "signUp.signUpIntroTitle2"
            )}`}
          </Text>
          <Text
            typography="body1"
            color={theme.color.neutral900}
            marginBottom={theme.spacing[16]}
          >
            {t("signUp.alreadyRegistered")}{" "}
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => navigate(SCREENS.SIGN_IN)}
            >
              {t("signUp.signIn")}
            </strong>
          </Text>

          <Flex
            alignItems="center"
            gap={theme.spacing[24]}
            marginBottom={theme.spacing[isMobile ? 24 : 48]}
          >
            <Text typography="label3" color="#232323">
              <Trans i18nKey="signUp.termPhrase" />
            </Text>
          </Flex>

          <Flex
            flexDirection="column"
            width="100%"
            maxWidth={390}
            padding={`${isMobile ? 0 : theme.spacing[16]}px 0px`}
            gap={theme.spacing[isMobile ? 24 : 32]}
            marginBottom={theme.spacing[isMobile ? 24 : 48]}
          >
            {authOptions?.map((option) => (
              <SocialAuthButton key={option?.text} {...option} />
            ))}
          </Flex>
        </Flex>
      </Section>
    </Layout>
  );
}

export default SignUpIntroContainer;
