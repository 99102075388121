import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");
googleProvider.setCustomParameters({ login_hint: "user@example.com" });

const facebookProvider = new FacebookAuthProvider();

export async function firebaseSignUp(email: string, password: string) {
  await createUserWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignIn(email: string, password: string) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignInWithGoogle() {
  return await signInWithPopup(auth, googleProvider);
}

export async function firebaseSignInWithFacebook() {
  return await signInWithPopup(auth, facebookProvider);
}
export async function firebaseSignInWithCustomToken(token: string) {
  return await signInWithCustomToken(auth, token);
}

export async function firebaseSignOut() {
  await signOut(auth);
}

export async function getFirebaseIdToken() {
  return await auth.currentUser?.getIdToken();
}

export async function sendFirebasePasswordResetEmail(email: string) {
  await sendPasswordResetEmail(auth, email);
}

export async function updateFirebasePassword(code: string) {
  if (auth.currentUser) {
    await updatePassword(auth.currentUser, code);
  }
}

export async function firebaseReAuthenticate(currentPassword: string) {
  const { currentUser } = auth;
  if (currentUser) {
    const credential = EmailAuthProvider.credential(
      currentUser?.email!,
      currentPassword
    );
    await reauthenticateWithCredential(currentUser, credential);
  }
}
