import React from "react";
import { Color } from "theme";
import Flex from "./Flex";
import theme from "../../../lib/theme";

interface IconButtonProps {
  fullWidth?: boolean;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  variant?: "contained" | "outline" | "text";
  icon?: string;
  disabled?: boolean;
  borderColor?: keyof Color;
  onClick?: () => void;
}

function IconButton({
  fullWidth = false,
  size = "md",
  variant = "contained",
  icon,
  disabled = false,
  borderColor = "neutral800",
  onClick,
}: IconButtonProps) {
  const padding = size === "lg" ? 16 : size === "md" ? 12 : 8;

  const bgColor = disabled
    ? theme.color.neutral200
    : variant === "contained"
    ? theme.color.neutral700
    : theme.color.neutralWhite;

  const iconSize =
    size === "xl"
      ? 32
      : size === "lg"
      ? 24
      : size === "md"
      ? 20
      : size === "sm"
      ? 16
      : 12;

  const border =
    variant === "outline" ? `1px solid ${theme.color[borderColor]}` : "none";

  return (
    <Flex
      width="fit-content"
      alignItems="center"
      justifyContent="center"
      borderRadius={6}
      padding={fullWidth ? 0 : theme.spacing[padding]}
      backgroundColor={bgColor}
      cursor={disabled ? "auto" : "pointer"}
      outline={border}
      onClick={onClick}
    >
      <img src={icon} style={{ width: iconSize, height: iconSize }} />
    </Flex>
  );
}

export default IconButton;
