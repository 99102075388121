import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSignUpStep1Container from "./SignUpStep1Container.hook";
import theme from "../../../../../lib/theme";
import Button from "../../../../common/components/Button";
import Flex from "../../../../common/components/Flex";
import Input from "../../../../common/components/Input";
import Layout from "../../../../common/components/Layout";
import Section from "../../../../common/components/Section";
import { useScreenWidthContext } from "../../../../common/provider/ScreenWidthProvider";
import SignUpStepper from "../../../common/components/SignUpStepper";

function SignUpStep1Container() {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const {
    models: { signingUp, state },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      isSubmitDisabled,
      onSubmit,
    },
  } = useSignUpStep1Container();

  const {
    email,
    password,
    confirmPassword,
    emailError,
    passwordError,
    confirmPasswordError,
  } = state;

  const memoizedEmailInput = useMemo(
    () => (
      <Input
        label={t("common.email")}
        placeholder={t("common.emailPlaceholder")}
        type="email"
        value={email}
        errorMessage={emailError}
        onFocus={() => onFocus("emailError")}
        onBlur={() => onBlur("emailError")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "email")
        }
      />
    ),
    [email, emailError]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <Input
        label={t("common.password")}
        placeholder={t("common.passwordPlaceholder")}
        type="password"
        value={password}
        errorMessage={passwordError}
        onFocus={() => onFocus("passwordError")}
        onBlur={() => onBlur("passwordError")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "password")
        }
      />
    ),
    [password, passwordError]
  );

  const memoizedConfirmPasswordInput = useMemo(
    () => (
      <Input
        label={t("common.confirmPassword")}
        placeholder={t("common.confirmPasswordPlaceholder")}
        type="password"
        value={confirmPassword}
        errorMessage={confirmPasswordError}
        onFocus={() => onFocus("confirmPasswordError")}
        onBlur={() => onBlur("confirmPasswordError")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "confirmPassword")
        }
        onKeyDown={onKeyDown}
      />
    ),
    [confirmPassword, confirmPasswordError]
  );

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <SignUpStepper />

        <Flex flexDirection="column" alignItems="center" width="100%">
          <Flex
            flexDirection="column"
            width="100%"
            gap={theme.spacing[isMobile ? 16 : 24]}
            marginBottom={theme.spacing[isMobile ? 16 : 48]}
          >
            {memoizedEmailInput}
            {memoizedPasswordInput}
            {memoizedConfirmPasswordInput}
          </Flex>
          <Button
            text={signingUp ? t("signUp.creatingAccount") : t("common.next")}
            size={isMobile ? "md" : "lg"}
            disabled={isSubmitDisabled()}
            onClick={onSubmit}
          />
        </Flex>
      </Section>
    </Layout>
  );
}

export default SignUpStep1Container;
