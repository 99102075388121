import React, { ComponentPropsWithRef } from "react";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import Text from "./Text";
import theme from "../../../lib/theme";

interface TextareaProps extends ComponentPropsWithRef<"textarea"> {
  label?: string | number;
  errorMessage?: string;
  summary?: string;
}

function Textarea({ label, errorMessage, summary, ...rest }: TextareaProps) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={label} />}
      {!!summary && (
        <Text typography="label2" color={theme.color.neutral600}>
          {summary}
        </Text>
      )}
      <textarea
        {...rest}
        style={{
          width: "100%",
          height: 107,
          resize: "none",
          padding: theme.spacing[12],
          border: `1px solid ${
            !!errorMessage ? theme.color.primary1 : theme.color.neutral500
          }`,
          borderRadius: 6,
          backgroundColor: theme.color.neutralWhite,
          ...theme.typography.body2,
          ...rest.style,
        }}
      />
      {!!errorMessage && (
        <Text typography="label3" color={theme.color.primary1}>
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
}

export default Textarea;
