import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../lib/theme";
import Button from "../../common/components/Button";
import Flex from "../../common/components/Flex";
import {
  GetSessionMessagesDocument,
  useSendMessageMutation,
} from "../../../lib/apollo/graphql/generated";

interface SessionChatFooterProps {
  sessionId: string;
  isHistory?: boolean;
}

function SessionChatFooter({
  sessionId,
  isHistory = false,
}: SessionChatFooterProps) {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [body, setBody] = useState("");

  const [sendMessage] = useSendMessageMutation();

  async function onSend() {
    if (body?.trim().length) {
      const { data } = await sendMessage({
        variables: {
          sessionId,
          body,
        },
      });
      try {
        const newMessage = data?.sendMessage;

        const getSessionMessages = client.readQuery({
          query: GetSessionMessagesDocument,
          variables: {
            sessionId,
            first: 9,
          },
        })?.getSessionMessages;

        const prevEdges = getSessionMessages?.edges || [];

        client.writeQuery({
          query: GetSessionMessagesDocument,
          variables: {
            sessionId,
            first: 10,
          },
          data: {
            getSessionMessages: {
              ...getSessionMessages,
              edges: [newMessage, ...prevEdges],
            },
          },
        });

        setBody("");
      } catch (err) {
        setBody("");
      }
    }
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      padding={theme.spacing[12]}
      gap={theme.spacing[20]}
      borderRadius={8}
      border={`1px solid ${theme.color.primary1}`}
      backgroundColor={isHistory ? theme.color.neutral200 : "white"}
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gap={theme.spacing[20]}
        flex={1}
      >
        <textarea
          disabled={isHistory}
          style={{
            width: "100%",
            border: "none",
            ...theme.typography.body1,
            height: 48,
            resize: "none",
          }}
          value={body}
          placeholder={t("session.chatPlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setBody(e.target.value)
          }
        />
      </Flex>
      <Button
        disabled={isHistory}
        size="sm"
        text={t("common.send")}
        variant={isHistory ? "contained" : "outline"}
        bgColor={isHistory ? "neutral200" : "neutralWhite"}
        fontColor={isHistory ? "neutral800" : "primary1"}
        onClick={onSend}
      />
    </Flex>
  );
}

export default SessionChatFooter;
