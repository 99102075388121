import { useState } from "react";

function useNavigationBar() {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function toggleProfileMenu() {
    setShowProfileMenu(!showProfileMenu);
  }

  function toggleMobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }

  return {
    models: {
      showProfileMenu,
      showMobileMenu,
    },
    operations: {
      toggleProfileMenu,
      toggleMobileMenu,
    },
  };
}

export default useNavigationBar;
