import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import SessionChatMessage from "./SessionChatMessage";
import FetchMore from "../../common/components/FetchMore";
import Flex from "../../common/components/Flex";
import { mergeMessagesInChronologicalOrder } from "../helpers/chatHelper";
import {
  NewMessageDocument,
  NewMessageSubscription,
  NewMessageSubscriptionVariables,
  useGetSessionMessagesQuery,
} from "../../../lib/apollo/graphql/generated";
import Text from "../../common/components/Text";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import theme from "../../../lib/theme";

interface SessionChatProps {
  sessionId: string;
  sessionAt: string;
}

function SessionChat({ sessionId, sessionAt }: SessionChatProps) {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLInputElement>(null);
  const { ref } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, subscribeToMore, fetchMore } =
    useGetSessionMessagesQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        sessionId,
        first: 15,
      },
    });

  useEffect(() => {
    const unsubscribeGetChatMessages = subscribeToMore<
      NewMessageSubscription,
      NewMessageSubscriptionVariables
    >({
      document: NewMessageDocument,
      variables: {
        sessionId: sessionId,
      },
      updateQuery: (prev, { subscriptionData }): any => {
        try {
          const newMessage = subscriptionData?.data?.newMessage;
          if (!newMessage) return prev;

          const prevMessages: any = prev?.getSessionMessages?.edges || [];

          return {
            getSessionMessages: {
              ...prev?.getSessionMessages,
              edges: mergeMessagesInChronologicalOrder(prevMessages, [
                newMessage,
              ]),
            },
          };
        } catch (err) {
          console.log("Error while processing subscribed chat message: ", err);
        }
      },
    });

    return () => {
      unsubscribeGetChatMessages();
    };
  }, []);

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getSessionMessages?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          sessionId,
          first: 10,
          after: data?.getSessionMessages?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return (
    <div style={{ height: "100%", overflowY: "hidden", position: "relative" }}>
      <div
        ref={wrapperRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
          gap: theme.spacing[12],
          padding: `${theme.spacing[16]}px ${theme.spacing[8]}px`,
        }}
      >
        <FetchMore fetchMoreRef={ref} />
        {[...(data?.getSessionMessages?.edges || [])]?.map((item) => (
          <SessionChatMessage key={item?.id} message={item} />
        ))}
        <Flex width="100%" alignItems="center" justifyContent="center">
          <Text
            textAlign="center"
            backgroundColor={theme.color.secondary3}
            color={theme.color.neutral900}
            paddingLeft={theme.spacing[8]}
            paddingRight={theme.spacing[8]}
            borderRadius={4}
          >
            {dayjs(sessionAt).format(t("common.dateFormat"))}
          </Text>
        </Flex>
      </div>
    </div>
  );
}

export default SessionChat;
