import { ReactChildrenProp } from "global";
import React, { CSSProperties } from "react";
import { Color, Typography } from "theme";
import theme from "../../../lib/theme";

interface TextProps extends ReactChildrenProp {
  typography?: keyof Typography;
}

function Text({
  children,
  typography = "body1",
  ...rest
}: TextProps & CSSProperties) {
  return (
    <div
      style={{
        color: theme.color.neutral900,
        ...theme.typography[typography],
        ...rest,
      }}
    >
      {children}
    </div>
  );
}

export default Text;
