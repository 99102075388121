import React from "react";
import theme from "../../../../lib/theme";
import Text from "../../../common/components/Text";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";

interface MyPageHeaderProps {
  title: string;
  isMain?: boolean;
}

function MyPageHeader({ title, isMain = false }: MyPageHeaderProps) {
  const { isMobile } = useScreenWidthContext();

  return (
    <Text
      typography={isMain ? "heading2" : isMobile ? "heading3" : "heading2"}
      marginBottom={theme.spacing[20]}
    >
      {title}
    </Text>
  );
}

export default MyPageHeader;
