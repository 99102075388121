import React from "react";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Button from "../../common/components/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function SessionHistoryController() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-end"
      padding={`${theme.spacing[16]}px ${theme.spacing[16]}px`}
      border={`1px solid black`}
      borderTop="none"
      gap={theme.spacing[12]}
    >
      <Flex alignItems="center" gap={theme.spacing[10]}>
        <Button
          text={t("common.leave")}
          size="lg"
          round={false}
          variant="outline"
          borderColor="neutral900"
          bgColor="tertiary1"
          fontColor="neutral900"
          onClick={() => navigate(-1)}
        />
      </Flex>
    </Flex>
  );
}

export default SessionHistoryController;
