import dayjs from "dayjs";

/**
 * Merge messages in chronological order
 * @param {[Message]} messages1
 * @param {[Message]} messages2
 */
export function mergeMessagesInChronologicalOrder(
  messages1: any,
  messages2: any
) {
  let ptr1 = 0;
  let ptr2 = 0;
  let mergedMessages = [];
  while (ptr1 < messages1.length && ptr2 < messages2.length) {
    const message1 = messages1[ptr1];
    const message2 = messages2[ptr2];

    if (dayjs(message1.createdAt).isAfter(dayjs(message2.createdAt))) {
      mergedMessages.push(message1);
      ptr1 += 1;
    } else {
      mergedMessages.push(message2);
      ptr2 += 1;
    }
  }

  while (ptr1 < messages1.length) {
    mergedMessages.push(messages1[ptr1]);
    ptr1 += 1;
  }

  while (ptr2 < messages2.length) {
    mergedMessages.push(messages2[ptr2]);
    ptr2 += 1;
  }

  return mergedMessages;
}
